import React, { useEffect, useRef } from 'react';


function BackgroundVideo({ video }) {
  const videoRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    const resizeVideo = () => {
      const container = containerRef.current;
      const vid = videoRef.current;

      const containerRatio = container.offsetWidth / container.offsetHeight;
      const videoRatio = vid.videoWidth / vid.videoHeight;

      if (videoRatio > containerRatio) {
        vid.style.width = '100%';
        vid.style.height = 'auto';
      } else {
        vid.style.width = 'auto';
        vid.style.height = '100%';
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', resizeVideo);
      window.addEventListener('resize', resizeVideo);
      resizeVideo();
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadedmetadata', resizeVideo);
        window.removeEventListener('resize', resizeVideo);
      }
    };
  }, []);


  return (
    <video autoPlay loop muted playsInline style={{
      opacity: 0.3,
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: -1, 
    }}>
      <source src={video} type="video/mp4" />
    </video>
  );
}

export default BackgroundVideo;
