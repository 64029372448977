import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home.js';
import About from './pages/Aboutus.js';
import Layout from './components/Layout.js';
import Galerija from './pages/GalleryItem.js';
import PredstaviSvojRad from './pages/Subscription.js';
import SavrsenPoklon from './pages/Gift.js';
import ProfilUmjetnika from './pages/ArtistProfile.js';
import ProfiliSvihUmjetnika from './pages/ProfilePage.js'
import Blog from './pages/BlogPage.js';
import BlogDetails from './pages/BlogDetails.js';
import Kontakt from './pages/Contact.js';
import PaintingDetails from './pages/PaintingDetails.js';
import FAQ from './pages/FAQPage.js';
import Login from './pages/Login.js';
import SubSubmit from './pages/SubSubmit.js';
import Kupi from './pages/Kupi.js';

import LoadingScreen from './components/LoadingScreen.js';

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);


  return isLoading ? <LoadingScreen /> : (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/onama" element={<About />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
          <Route path="/blog/:blogId" element={<BlogDetails />} />
          <Route path="/g" element={<Galerija />} />
          <Route path="/rd" element={<PredstaviSvojRad />} />
          <Route path="/sp" element={<SavrsenPoklon />} />
          <Route path="/pu/" element={<ProfiliSvihUmjetnika />} />
          <Route path="/pu/:artistId" element={<ProfilUmjetnika />} />
          <Route path="/p/:paintingId" element={<PaintingDetails />} />
          <Route path="/kt" element={<Kontakt />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pr" element={<SubSubmit />}/>
          <Route path="/kupi" element={<Kupi />}/>
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
