import React, { useState } from 'react';
import './Contact.css';
import NewsLetter from '../components/Newsletter.js';
import { useTranslation } from 'react-i18next';



function Contact() {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <section className="contact">
      <div className="container">
        <h2>{t('KontaktirajteNas')}</h2>
        <h3>{t('PorukaZaPretplatu')}</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">{t('NAme')}</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">{t('Email')}</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="message">{t('Poruka')}</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange} required />
          </div>
          <button type="submit">{t('Posalji')}</button>
        </form>
        <h2 className="newsletter">{t('Pretplatite_se_besplatno')}</h2>
        <NewsLetter />
      </div>
    </section>
  );
}

export default Contact;
