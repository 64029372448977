import React, { createContext, useState, useEffect } from 'react';
import i18n from 'i18next';

const TranslationContext = createContext({
  currentLanguage: 'bs',
  toggleLanguage: () => { },
});

export const TranslationProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState('bs');

  useEffect(() => {

    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    } else {

      i18n.changeLanguage('bs');
    }
  }, []);

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'bs' ? 'en' : 'bs';
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);

    localStorage.setItem('language', newLanguage);
  };

  return (
    <TranslationContext.Provider value={{ currentLanguage, toggleLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationContext;
