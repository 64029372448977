import React, { useEffect, useRef    } from 'react';
import './LoadingScreen.css';
import logo from '../fonts/logo bez teksta bijeli.png';

function LoadingScreen() {
    const loadingScreenRef = useRef(null);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (loadingScreenRef.current) {
                loadingScreenRef.current.classList.add('fade-out');
            }

            setTimeout(() => {
                if (loadingScreenRef.current) {
                    loadingScreenRef.current.style.display = 'none';
                }
            }, 1000);
        }, 3000);

        return () => clearTimeout(timer); 
    }, []);

    return (
        <div id="loading-screen">
            <img src={logo} alt="Logo" className="loading-logo" />
        </div>
    );
}

export default LoadingScreen;
