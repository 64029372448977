import React from 'react';
import './Footer.css';
import logo from '../fonts/logo bez teksta bijeli.png';
import { useTranslation } from 'react-i18next';
import Sitelinks from './Sitelinks.js';


function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section logo-and-links">
          <img src={logo} alt="Your Logo" className="footer-logo" />
        </div>

        <div className="footer-section links">
          <h3>{t('see_more')}</h3>
          <ul>
            <li><a href="/onama">{t('about_us')}</a></li>
            <li><a href="/gallery">{t('gallery')}</a></li>
            <li><a href="/artists">{t('artists')}</a></li>
            <li><a href="/careers">{t('careers')}</a></li>
            <li><a href="/contact">{t('contact')}</a></li>
          </ul>
        </div>

        <div className="footer-section-contact">
          <h3>{t('contact_us')}</h3>
          <p>Email: digitalnagalerija@gmail.com</p>
          <p>+387 61 031 188</p>
          <p>{t('sarajevo')}</p>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} BH Digitalna Galerija. {t('all_rights_reserved')}</p>
      </div>
      <Sitelinks />
    </footer>
  );
}

export default Footer;
