import ArtistCard from './Profile.js';
import React, { useState, useEffect } from 'react';
import './ProfilePage.css'
import Spacer from '../components/Spacer.js';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


function ArtistList({ }) {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [artists, setArtists] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(1);

    useEffect(() => {

        const API_URL = process.env.NODE_ENV === 'production'
            ? 'https://digitalnagalerija.ba'
            : 'http://localhost:3001';

        setIsLoading(true);
        fetch(`${API_URL}/api/artists?page=${currentPage}&perPage=100`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok (status: ${response.status})`);
                }
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data.artworks)) {
                    console.log('artist data', data.artworks)
                    const adaptedArtists = data.artworks.map(artwork => ({
                        id: artwork.artist._id || artwork._id, 
                        name: artwork.artist.name,
                        city: artwork.location,
                        imageUrl: artwork.artist.imageUrl,
                        bio: artwork.artist.bio
                    }));

                    const uniqueArtists = adaptedArtists.filter((artist, index, self) =>
                        index === self.findIndex((a) => a.name === artist.name)
                    ).filter(artist => artist.name !== "Emir Berbic");
            
                    setArtists(uniqueArtists); 
                } else {
                    console.error('API respon.se does not contain an "artists" array:', data);
                }
                setIsLoading(false);
            })
            .catch(error => console.error('Error fetching artists:', error));
       
    }, [currentPage]);


    useEffect(() => {
        const visiblePages = 5;
        const halfVisible = Math.floor(visiblePages / 2);

        let newStartPage = Math.max(1, currentPage - halfVisible);
        let newEndPage = Math.min(totalPages, newStartPage + visiblePages - 1);

        if (newEndPage > totalPages) {
            newEndPage = totalPages;
            newStartPage = Math.max(1, newEndPage - visiblePages + 1);
        }

        setStartPage(newStartPage);
        setEndPage(newEndPage);
    }, [currentPage, totalPages]); 


    const navigate = useNavigate();

    const handleClick = (artist) => {
        navigate(`/artwork`, { state: { artist } });
    };

    if (isLoading) {
        return <div className="artist-list-title">Stranica se učitava, molimo sačekajte</div>;
    }
    return (
        <>
            <h2 className="artist-list-title">{t('artist_list_title')}</h2>
            <p className="artist-list-intro">{t('artist_list_intro')}</p>

            <div className="artist-list" >
                {artists.map(artist => (
                    <ArtistCard key={artist._id} 
                    artist={artist} 
                    onClick={() => handleClick(artist)} />
                ))}
            </div>

            <div className="pagination">

                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                    {t('previous')}
                </button>

                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                    <button
                        key={startPage + index}
                        onClick={() => setCurrentPage(startPage + index)}
                        className={currentPage === startPage + index
                            ? 'active' : ''}
                    >
                        {startPage + index}
                    </button>
                ))}

                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                    {t('next')}
                </button>

            </div>

            <Spacer height={80} />

        </>
    );
}

export default ArtistList;

