// src/pages/Onama.js
import React, { useState, useEffect } from 'react';
import './Aboutus.css'; // Import your CSS file for styling
import video from '../fonts/blogimages/womanart.mp4';
import BackgroundVideo from '../components/BackgroundVideo.js';
import image1 from '../fonts/Creative Studio Name LinkedIn Article Cover Image.png'
import image2 from '../fonts/Creative Studio Name LinkedIn Article Cover Image (1).png'
import image3 from '../fonts/Creative Studio Name LinkedIn Article Cover Image (2).png'
import { useTranslation } from 'react-i18next';


function Aboutus() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1300);
  }, []);

  
  return (
    <>
      <BackgroundVideo video={video} />
      <section className={`about-us ${isLoading ? 'loading' : 'loaded'}`}>
        <h1>{t('about_us')}</h1>
        <div className="container">
          <div className="about-content">
            <p className='first-container'>
              {t('welcome_gallery')}
            </p>
            <img className="image-first" src={image1} alt={t('art_explained')} />
            <img className="image-second" src={image2} alt={t('art_explained')} />
            <p className='second-container'>
              {t('our_mission')}
            </p>
            <p className='first-container'>
              {t('our_vision')}
            </p>
            <img className="image-third" src={image3} alt={t('art_explained')} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Aboutus;
