import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './PaintingDetails.css';
import { useTranslation } from 'react-i18next';


function PaintingDetails() {
  const { t } = useTranslation();


  const { paintingId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [paintingData, setPaintingData] = useState(
    location.state?.artwork || null
  );


  const handleBuyClick = () => {
    navigate('/kupi', { state: { artwork: paintingData } });
  };

  const API_URL = process.env.NODE_ENV === 'production'
    ? 'https://digitalnagalerija.ba'
    : 'http://localhost:3001';

  useEffect(() => {
    const fetchPainting = async () => {
      try {
        const artworkFromLocationState = location.state?.artwork

        if (
          artworkFromLocationState &&
          artworkFromLocationState.id.toString() === paintingId
        ) {
          setPaintingData(artworkFromLocationState);
        } else {
          const response = await fetch(`${API_URL}/api/artists`);
          if (response.ok) {
            const artistsData = await response.json();
            const allArtworks = artistsData.flatMap((artist) => artist.artwork);
            const artwork = allArtworks.find(
              (art) => art._id.toString() === paintingId
            );
            if (artwork) {
              setPaintingData(artwork);
            } else {
              setError("Could not find artwork.");
            }
          } else {
            throw new Error("Could not fetch artists");
          }
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPainting();
  }, [paintingId, location.state]);



  if (!paintingData) {
    return <div className="art-description-title">Umjetnina nije pronađena</div>;
  }

  return (
    <section className="painting-details">

      <div className="painting-content">
        <div className="painting-image-container">
          <img src={paintingData.imageUrl} alt={paintingData.title} className="painting-image" />
        </div>
        <div className="painting-info">
          <h2>{paintingData.title}</h2>

          <table className="painting-details-table">
            <tbody>
              <tr>
                <th>{t('zanr')}</th>
                <td>{paintingData.genre}</td>
              </tr>
              <tr>
                <th>{t('cijena')}</th>
                <td>{paintingData.price}</td>
              </tr>
              <tr>
                <th>{t('dimenzija')}</th>
                <td>{paintingData.dimensions}</td>
              </tr>
              <tr>
                <th>{t('lokacija')}</th>
                <td>{paintingData.location}</td>
              </tr>
              <tr>
                <th>{t('godina')}</th>
                <td>{paintingData.year}</td>
              </tr>
            </tbody>
          </table>
          <h3 className="art-description-title">{t('opis')}</h3>
          <div className="art-description">{paintingData.description}</div>
        </div>
        <button className="buy-button" onClick={handleBuyClick}>
          {t('kupi')}
        </button>
      </div>
      <h2 className='comming-soon'>USKORO I NA DESKTOP PREGLEDU</h2>
    </section>
  );
}

export default PaintingDetails;
