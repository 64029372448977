import React, { useState } from 'react';
import './ArtistProfileForm.css';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

function ArtistProfileForm() {
  const [formData, setFormData] = useState({
    artistId: uuidv4(),
    name: '',
    city: '',
    bio: '',
    artistImageFile: null,
    artwork: [{ id: uuidv4(), title: '', genre: '', description: '', price: '', dimensions: '', location: '', year: '', imageFile: null }],
  });

  const navigate = useNavigate();
  const handleInputChange = (e, index, field) => {
    const updatedArtwork = [...formData.artwork];
    updatedArtwork[index][field] = e.target.value;
    setFormData({ ...formData, artwork: updatedArtwork });
  };

  const addArtworkField = () => {
    setFormData({
      ...formData,
      artwork: [...formData.artwork, { id: uuidv4(), title: '', genre: '', description: '', price: '', dimensions: '', location: '', year: '', imageFile: null }],
    });

  };

  const handleImageChange = (e, index, isArtistImage = false) => {
    const newFormData = { ...formData };

    if (isArtistImage) {
      newFormData.artistImageFile = e.target.files[0];
    } else {
      const updatedArtwork = [...formData.artwork];
      updatedArtwork[index].imageFile = e.target.files[0];
      newFormData.artwork = updatedArtwork;
    }

    setFormData(newFormData);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('data from id', formData.artwork[0].id)

    const imageFormData = new FormData();

    // Append artist image (if available)
    if (formData.artistImageFile) {
      imageFormData.append('artistImage', formData.artistImageFile);
    }

    formData.artwork.forEach((artwork, index) => {
      // Append only artwork images that have been selected
      if (artwork.imageFile) {
        imageFormData.append(`images`, artwork.imageFile);
      }
    });

    const API_URL = process.env.NODE_ENV === 'production'
      ? 'https://digitalnagalerija.ba'
      : 'http://localhost:3001';

    try {
      // 1. Upload Images and Get URLs
      console.log('imagedata', JSON.stringify(imageFormData));
      const uploadResponse = await fetch(`${API_URL}/upload`, {
        method: 'POST',
        body: imageFormData
      });
      const imageUrls = await uploadResponse.json();

      // 2. Prepare Artist Data with Image URLs (Remove imageFile from artwork)
      const artistData = {
        artistId: formData.artistId,
        name: formData.name,
        city: formData.city,
        bio: formData.bio,
        imageUrl: imageUrls.artistImage, // Set artist image URL
        artwork: formData.artwork.map((artwork, index) => ({
          id: artwork.id,
          title: artwork.title,
          genre: artwork.genre,
          description: artwork.description,
          price: artwork.price,
          dimensions: artwork.dimensions,
          location: artwork.location,
          year: artwork.year,
          imageUrl: imageUrls.images && imageUrls.images[index] ? imageUrls.images[index] : null,
        }))
      };

      // 3. Send Artist Data to /create-artist
      const createArtistResponse = await fetch(`${API_URL}/api/artists/create-artist`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(artistData)
      });

      if (createArtistResponse.ok) {
        const newArtist = await createArtistResponse.json();
        console.log('Artist created:', newArtist);
        navigate('/');
        // ... navigate to the artist's profile page or do something else
      } else {
        const errorData = await createArtistResponse.json();
        console.error('Error creating artist:', errorData.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <form onSubmit={handleSubmit} className="artist-profile-form">
      {/* Artist Details */}
      <h3>Lični podaci:</h3>
      <div className="input-group">
        <label htmlFor="name">Ime:</label>
        <input type="text" id="name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
      </div>
      <div className="input-group">
        <label htmlFor="city">Grad:</label>
        <input type="text" id="city" value={formData.city} onChange={(e) => setFormData({ ...formData, city: e.target.value })} required />
      </div>
      <div className="input-group">
        <label htmlFor="artistImage">Slika:</label>
        <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, null, true)} name="artistImage" /> {/* Name the input 'artistImage' to match the backend */}
      </div>
      <div className="input-group">
        <label htmlFor="bio">Biografija:</label>
        <textarea id="bio" value={formData.bio} onChange={(e) => setFormData({ ...formData, bio: e.target.value })} required />
      </div>

      {/* Artwork Details */}
      <h3>Radovi:</h3>
      {formData.artwork.map((artwork, index) => (
        <div key={index} className="artwork-input-group">
          <input type="text" placeholder="Naslov" value={artwork.title} onChange={(e) => handleInputChange(e, index, 'title')} required />
          <input type="text" placeholder="Žanr" value={artwork.genre} onChange={(e) => handleInputChange(e, index, 'genre')} required />
          <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, index)} name="images" />  {/* Name the input 'images' to match the backend */}
          <textarea placeholder="Opis" value={artwork.description} onChange={(e) => handleInputChange(e, index, 'description')} required />
          <input type="text" placeholder="Cijena" value={artwork.price} onChange={(e) => handleInputChange(e, index, 'price')} required />
          <input type="text" placeholder="Dimenzije" value={artwork.dimensions} onChange={(e) => handleInputChange(e, index, 'dimensions')} required />
          <input type="text" placeholder="Lokacija" value={artwork.location} onChange={(e) => handleInputChange(e, index, 'location')} required />
          <input type="number" placeholder="Godina" value={artwork.year} onChange={(e) => handleInputChange(e, index, 'year')} required />
        </div>
      ))}
      <button type="button" onClick={addArtworkField} className="add-artwork-button">+ Dodaj još jedan rad</button>
      <button type="submit">Sačuvaj profil</button>
    </form>
  );
}



export default ArtistProfileForm;
