import React, { useState, useEffect } from 'react';
import './Gift.css';
import BackgroundVideo from '../components/BackgroundVideo.js';
import video from '../fonts/blogimages/manart.mp4';
import GalleryCard from './Gallery.js'
import { useTranslation } from 'react-i18next';



function Gift() {
  const [affordableArt, setAffordableArt] = useState([]);
  const { t } = useTranslation();


  useEffect(() => {


    const API_URL = process.env.NODE_ENV === 'production'
      ? 'https://digitalnagalerija.ba'
      : 'http://localhost:3001';

    const fetchArtworks = async () => {
      try {
        const response = await fetch(`${API_URL}/api/artists`);
        if (!response.ok) {
          throw new Error('Could not fetch artists');
        }
        const artistsData = await response.json();

        const allArtworks = artistsData.artworks.map(artwork => artwork); 
        setAffordableArt(allArtworks);
      } catch (error) {
        console.error('Error fetching artworks:', error);
      }
    };

    fetchArtworks();
  }, []);


  return (
    <>
      <BackgroundVideo video={video} className="video-gallery" />
      <h2 className="artist-list-title">{t('gift_with_taste')}</h2>
      <p className="artist-list-intro">{t('gift_intro')}</p>

      <div className="artist-list" >
        {affordableArt.map(artwork => (
          <GalleryCard key={artwork.id} artwork={artwork} />
        ))}
      </div>
    </>
  );
}

export default Gift;
