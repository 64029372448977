import React, { useState } from 'react';
import './Newsletter.css';

function NewsletterForm() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('Hvala Vam! Uživajte u sedmičnom Newsletter-u.');
        setEmail('');
      } else {
        setMessage('Došlo je do greške. Molimo Vas probajte kasnije.');
      }
    } catch (error) {
      setMessage('Došlo je do greške. Molimo Vas probajte kasnije.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="newsletter-form">
      <input
        type="email"
        placeholder="Unesite email adresu"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button  className="newsletter-form-button" type="submit">PRETPLATI SE</button>
      {message && <p>{message}</p>}
    </form>
  );
}

export default NewsletterForm;
