import photo from '../fonts/ZvezdanaMili.jpg';
import photo3 from '../fonts/AbdulHak.jpg';
import photo2 from '../fonts/IzudinDru.jpg';
import image from '../fonts/Rumi1.jfif'
import image2 from '../fonts/Rumi2.jfif'
import image3 from '../fonts/Rumi3.jfif'

import giftImage from '../fonts/lotusi.jpg'
import giftImage2 from '../fonts/2 izudin.jpeg'
import giftImage3 from '../fonts/Margarete.jpeg'

const featuredArtist = {
  name: 'Zvezdana Milidrag',
  imageUrl: photo,
  description: 'Zvezdana je umjetnica, čiji radovi oduševljavaju svojom originalnošću i dubinom.',
  id: '/pu/1'
};

const featuredArtist2 = {
  name: 'Izudin Duraković',
  imageUrl: photo2,
  description: 'Izudin je kaligraf iz Sarajeva, čije umjetnine oduzimaju dah',
  id: '/pu/d6da8edc-f71c-4978-a6f4-8d15b0d68442'
};

const featuredArtist3 = {
  name: 'Abdulhak Hadžimejlić',
  imageUrl: photo3,
  description: 'Abdulhak je kaligraf iz Sarajeva, koji svojom orginalnošću osvaja svijet',
  id: '/pu/41b646c1-ac3b-4001-af42-46d25f579899'
};

const fetchLatestPost = {
  id: 1,
  title: 'Umjetnost Rumija kao stil života',
  name: 'Esma Mrahorović',
  imageUrl: image,
  excerpt: 'Rumi u svojoj poeziji kao da naslikava riječima svoje poruke, ova slika vuče inspiraciju iz..',
};

const fetchLatestPost2 = {
  id: 2,
  title: 'Žašto nas AI nikada neće zamijeniti?',
  name: 'Adnan Mehremić',
  imageUrl: image2,
  excerpt: 'Uz samo nekoliko minuta truda jedan amater će moći doseći do digitalne umjetnosti..',
};

const fetchLatestPost3 = {
  id: 3,
  title: 'Želim da AI za mene, ne da on pravi umjetnine, dok ja radim',
  name: 'Esma Mrahorović',
  imageUrl: image3,
  excerpt: 'Sve nas laike koji se baš i ne razumijemo u AI, da li će ns zamijeniti..',
};

const artGift = {
  name: 'Lotusi',
  imageUrl: giftImage,
  description: 'Originalan pristup umjetnosti garantira da će vaša draga osoba uživati.',
  id: '/p/101' 
};
const artGift2 = {
  name: 'Bismilla',
  imageUrl: giftImage2,
  description: 'Originalan pristup umjetnosti garantira da će vaša draga osoba uživati.',
  id: '/p/101' 
};
const artGift3 = {
  name: 'Margarete',
  imageUrl: giftImage3,
  description: 'Originalan pristup umjetnosti garantira da će vaša draga osoba uživati.',
  id: '/p/101' 
};


export default {featuredArtist, featuredArtist2, featuredArtist3, artGift, artGift2, artGift3, fetchLatestPost,fetchLatestPost2, fetchLatestPost3}